<template>
  <b2b-layout
    v-if="!isLoading"
    :page-title="t('pageTitle')"
    :heading="t('pageTitle')"
    :back-link-label="t('backToProductsAndServices')"
    :back-link-href="`/foretag/mybusiness/${scopeId}/bestall/produkter-tjanster`"
    :show-organisation-selector="true"
    @organisationSelected="setOrganisation($event.detail)"
  >
    <span slot="header">
      <telia-grid>
        <telia-p>{{ t("pageSubTitle") }}</telia-p>
      </telia-grid>
    </span>
    <div>
      <transition-group name="fade" mode="out-in">
        <telia-grid class="form-container" key="form-container">
          <telia-row v-if="hasLoadingError" class="notification-container">
            <telia-col width="6">
              <telia-notification
                t-id="loading-error-notification"
                variant="inline"
                status="warning"
                heading-tag="h3"
                html-aria-live="polite"
                html-role="alert"
              >
                <span slot="heading">
                  <telia-visually-hidden>
                    {{ t("a11yWarningStatus") }}
                  </telia-visually-hidden>
                  <telia-p>
                    {{ state.loading.message }}
                  </telia-p>
                </span>
              </telia-notification>
            </telia-col>
          </telia-row>
          <telia-form name="b2b-order-iot-sim" autocomplete="off" @submit.prevent>
            <telia-row v-if="!hasLoadingError" class="input-group-container">
              <telia-col width="12">
                <div v-if="isLoading">
                  <telia-skeleton class="section-title-skeleton" />
                  <div class="input-skeleton-wrapper">
                    <b2x-skeleton-input />
                  </div>
                  <div class="input-skeleton-wrapper">
                    <b2x-skeleton-input />
                  </div>
                  <div class="input-skeleton-wrapper">
                    <b2x-skeleton-input />
                  </div>
                  <div class="input-skeleton-wrapper">
                    <b2x-skeleton-input />
                  </div>
                  <div class="input-skeleton-wrapper">
                    <b2x-skeleton-input />
                  </div>
                  <div class="input-skeleton-wrapper">
                    <b2x-skeleton-input />
                  </div>
                </div>
                <div v-else>
                  <telia-heading class="section-title" tag="h2" variant="title-200">
                    {{ t("deliveryInfo") }}
                  </telia-heading>
                  <div class="input-wrapper">
                    <telia-text-input
                      t-id="delivery-name-input"
                      required
                      inputmode="text"
                      :label="t('receiverName')"
                      :value="form.delivery.fullName"
                      @input="form.delivery.fullName = $event.target.value"
                      :disabled="isSubmitting"
                      :required-error-message="t('receiverNameRequired')"
                    />
                  </div>
                  <div class="input-wrapper input-wrapper__custom-error">
                    <telia-text-input
                      t-id="delivery-phone-number-input"
                      required
                      inputmode="tel"
                      :label="t('receiverPhoneNumber')"
                      :value="form.delivery.telephoneNumber"
                      @input="setReceiverPhoneNumber($event.target.value)"
                      :disabled="isSubmitting"
                      :required-error-message="t('receiverPhoneNumberRequired')"
                      :error-message="!isValidPhoneNumber ? t('receiverPhoneNumberRequired') : ''"
                    />
                  </div>
                  <div class="input-wrapper">
                    <telia-text-input
                      t-id="delivery-street-input"
                      required
                      :label="t('street')"
                      :value="form.delivery.address.street"
                      @input="form.delivery.address.street = $event.target.value"
                      :disabled="isSubmitting"
                      :required-error-message="t('streetRequired')"
                    />
                  </div>
                  <div class="input-wrapper">
                    <telia-text-input
                      t-id="delivery-postal-code-input"
                      required
                      inputmode="numeric"
                      :label="t('postalCode')"
                      :value="form.delivery.address.postalCode"
                      @input="form.delivery.address.postalCode = $event.target.value"
                      :disabled="isSubmitting"
                      :required-error-message="t('postalCodeRequired')"
                    />
                  </div>
                  <div class="input-wrapper">
                    <telia-text-input
                      t-id="delivery-city-input"
                      required
                      :label="t('city')"
                      :value="form.delivery.address.city"
                      @input="form.delivery.address.city = $event.target.value"
                      :disabled="isSubmitting"
                      :required-error-message="t('cityRequired')"
                    />
                  </div>
                  <div class="input-wrapper">
                    <telia-text-input
                      t-id="delivery-country-input"
                      inputmode="text"
                      :label="t('country')"
                      :value="form.delivery.address.country"
                      @input="form.delivery.address.country = $event.target.value"
                      :disabled="isSubmitting"
                    />
                  </div>
                </div>
              </telia-col>
            </telia-row>
            <telia-row v-if="!hasLoadingError" class="input-group-container">
              <telia-col width="12">
                <div v-if="isLoading" class="chip-choice-container">
                  <telia-skeleton class="section-title-skeleton" />
                  <div class="input-skeleton-wrapper">
                    <telia-skeleton class="chip-choice-skeleton" />
                  </div>
                </div>
                <div v-else class="chip-choice-container">
                  <telia-heading class="section-title" tag="h2" variant="title-200">
                    {{ t("simCardSize") }}
                  </telia-heading>
                  <telia-chip-choice
                    t-id="sim-card-size-input"
                    :text="t('simCardSizeOptions.4FF')"
                    value="TRIO_SIM"
                    :checked="form.simCards.size === 'TRIO_SIM'"
                  />
                </div>
              </telia-col>
            </telia-row>
            <telia-row v-if="!hasLoadingError" class="input-group-container">
              <telia-col width="12">
                <div v-if="isLoading">
                  <telia-skeleton class="section-title-skeleton" />
                  <telia-skeleton class="section-paragraph-skeleton" />
                  <div class="input-skeleton-wrapper">
                    <b2x-skeleton-input />
                  </div>
                  <div class="input-skeleton-wrapper">
                    <b2x-skeleton-input />
                  </div>
                </div>
                <div v-else>
                  <telia-heading class="section-title" tag="h2" variant="title-200">
                    {{ t("nrOfPackages") }}
                  </telia-heading>
                  <telia-p class="section-paragraph">
                    {{ t("simCardInfo") }}
                  </telia-p>
                  <div class="input-wrapper">
                    <telia-select
                      t-id="nr-of-100-packs-input"
                      :label="t('nrOf100PackSimCards')"
                      :options="JSON.stringify(numberOf100PackSimCardsOptions)"
                      @change="setSimCardPack('100', $event.target.value)"
                      :disabled="isSubmitting"
                    />
                  </div>
                  <div class="input-wrapper">
                    <telia-select
                      t-id="nr-of-500-packs-input"
                      :label="t('nrOf500PackSimCards')"
                      :options="JSON.stringify(numberOf500PackSimCardsOptions)"
                      @change="setSimCardPack('500', $event.target.value)"
                      :disabled="isSubmitting"
                    />
                  </div>
                  <telia-field-error v-if="!isValidSimCardPackSelection">
                    {{ t("packageRequired") }}
                  </telia-field-error>
                </div>
              </telia-col>
            </telia-row>
            <telia-row v-if="!hasLoadingError" class="input-group-container">
              <telia-col width="12">
                <div v-if="isLoading">
                  <telia-skeleton class="section-title-skeleton" />
                  <telia-skeleton class="section-paragraph-skeleton" />
                  <div class="input-skeleton-wrapper">
                    <b2x-skeleton-input />
                  </div>
                  <div class="input-skeleton-wrapper">
                    <b2x-skeleton-input />
                  </div>
                </div>
                <div v-else>
                  <telia-heading class="section-title" tag="h2" variant="title-200">
                    {{ t("deliveryOfIccNr") }}
                  </telia-heading>
                  <telia-p class="section-paragraph">
                    {{ t("iccNrInfo") }}
                  </telia-p>
                  <div class="input-wrapper">
                    <telia-text-input
                      t-id="icc-primary-email-input"
                      required
                      type="email"
                      inputmode="email"
                      :label="t('primaryEmail')"
                      :value="form.delivery.icc.primaryEmail"
                      @input="form.delivery.icc.primaryEmail = $event.target.value"
                      :disabled="isSubmitting"
                      :required-error-message="t('emailRequired')"
                      :email-error-message="t('emailRequired')"
                    />
                  </div>
                  <div class="input-wrapper">
                    <telia-text-input
                      t-id="icc-secondary-email-input"
                      type="email"
                      inputmode="email"
                      :label="t('secondaryEmail')"
                      :value="form.delivery.icc.secondaryEmail"
                      @input="form.delivery.icc.secondaryEmail = $event.target.value"
                      :disabled="isSubmitting"
                    />
                  </div>
                </div>
              </telia-col>
            </telia-row>
            <telia-row v-if="!hasLoadingError" class="input-group-container">
              <telia-col width="12">
                <div v-if="isLoading">
                  <telia-skeleton class="section-title-skeleton" />
                  <div class="input-skeleton-wrapper">
                    <b2x-skeleton-input />
                  </div>
                </div>
                <div v-else>
                  <telia-heading class="section-title" tag="h2" variant="title-200">
                    {{ t("deliveryOutsideOfEu") }}
                  </telia-heading>
                  <div class="input-wrapper">
                    <telia-text-input
                      t-id="customs-credit-number-input"
                      :label="t('customsCreditNr')"
                      :value="form.delivery.customsCreditNumber"
                      @input="form.delivery.customsCreditNumber = $event.target.value"
                      :disabled="isSubmitting"
                    />
                  </div>
                </div>
              </telia-col>
            </telia-row>
            <telia-row v-if="!hasLoadingError">
              <telia-col width="12">
                <div v-if="isLoading">
                  <telia-skeleton class="button-skeleton" round />
                </div>
                <telia-button
                  v-else
                  t-id="submit-form-button"
                  type="submit"
                  variant="primary"
                  @click="submit"
                  :disabled="isTeliaAdmin || isSubmitting"
                >
                  <span>{{ submitButtonLabel }}</span>
                </telia-button>
              </telia-col>
            </telia-row>
          </telia-form>
          <telia-row v-if="!isValidOrganisation" class="form-error-container">
            <telia-col width="6">
              <telia-notification
                ref="organisation-error-notification"
                t-id="organisation-error-notification"
                variant="inline"
                status="warning"
                heading-tag="h3"
                html-aria-live="polite"
                html-role="alert"
              >
                <span slot="heading">
                  <telia-visually-hidden>
                    {{ t("a11yWarningStatus") }}
                  </telia-visually-hidden>
                  <telia-p>
                    {{ t("errors.invalidOrganisation") }}
                  </telia-p>
                </span>
              </telia-notification>
            </telia-col>
          </telia-row>
          <telia-row
            v-if="!isValidPhoneNumber || !isValidSimCardPackSelection"
            class="form-error-container"
          >
            <telia-col width="6">
              <telia-notification
                ref="form-error-notification"
                t-id="form-error-notification"
                variant="inline"
                status="warning"
                heading-tag="h3"
                html-aria-live="polite"
                html-role="alert"
              >
                <span slot="heading">
                  <telia-visually-hidden>
                    {{ t("a11yWarningStatus") }}
                  </telia-visually-hidden>
                  <telia-p>
                    {{ t("errors.invalidFormData") }}
                  </telia-p>
                </span>
              </telia-notification>
            </telia-col>
          </telia-row>
        </telia-grid>
      </transition-group>
      <modal
        v-if="showModal"
        modal-id="order-confirmation-modal"
        t-id="order-confirmation-modal"
        ref="order-confirmation-modal"
        :is-open="showModal"
        @closeModal="closeModalAndResetForm"
      >
        <template v-slot:modal-body>
          <div>
            <telia-heading tag="h2" variant="title-200" class="modal-title">
              {{ t("orderConfirmationTitle") }}
            </telia-heading>
            <telia-p>
              {{ state.submitting.message }}
            </telia-p>
          </div>
        </template>
        <template v-slot:button-footer>
          <div class="modal-button-footer">
            <telia-button
              t-id="close-modal-button"
              variant="primary"
              @click="closeModalAndResetForm"
            >
              {{ t("close") }}
            </telia-button>
          </div>
        </template>
      </modal>
    </div>
  </b2b-layout>
</template>

<script>
import { translateSetup, translateMixin } from "./locale";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import {
  getOrganisations,
  getLoggedInUserInfo,
  isTeliaAdmin,
  hasPermission,
} from "@telia/b2b-logged-in-service";
import { corpValidationService } from "@telia/b2b-rest-client";
import { servicesCorpBulkSimOrderV1_0_0 } from "@telia/b2x-rest-client";
import modal from "./components/modal.vue";
import "@telia/b2x-skeleton-input";

const State = Object.freeze({
  LOADING: "loading",
  SUBMITTING: "submitting",
});

const Status = Object.freeze({
  IDLE: "idle",
  PENDING: "pending",
  REJECTED: "rejected",
  RESOLVED: "resolved",
});

const ValidationType = Object.freeze({
  PHONE_NUMBER: "PHONENUMBER",
  EMAIL: "EMAIL",
});

const SimCardPackSize = Object.freeze({
  ONE_HUNDRED: "100",
  FIVE_HUNDRED: "500",
});

export default {
  name: "b2b-order-iot-sim",
  mixins: [translateMixin],
  components: {
    modal,
  },
  data() {
    return {
      state: {
        loading: {
          status: Status.PENDING,
          message: "",
        },
        submitting: {
          status: Status.IDLE,
          message: "",
        },
      },
      scopeId: "",
      isTeliaAdmin: false,
      organisations: [],
      isValidOrganisation: true,
      isValidPhoneNumber: true,
      isValidSimCardPackSelection: true,
      showModal: false,
      form: {
        customer: {
          organisation: {
            number: "",
          },
          email: "",
        },
        delivery: {
          address: {
            street: "",
            postalCode: "",
            city: "",
            country: "",
          },
          customsCreditNumber: "",
          fullName: "",
          icc: {
            primaryEmail: "",
            secondaryEmail: "",
          },
          telephoneNumber: "",
        },
        simCards: {
          size: "TRIO_SIM",
          numberOf100Packs: 0,
          numberOf500Packs: 0,
        },
      },
    };
  },
  computed: {
    numberOf100PackSimCardsOptions() {
      return [
        {
          label: this.t("noPackages"),
          value: 0,
        },
        {
          label: this.t("packageOptionLabel", { packages: 1, simCards: 100 }),
          value: 1,
        },
        {
          label: this.t("packageOptionLabel", { packages: 2, simCards: 200 }),
          value: 2,
        },
        {
          label: this.t("packageOptionLabel", { packages: 3, simCards: 300 }),
          value: 3,
        },
        {
          label: this.t("packageOptionLabel", { packages: 4, simCards: 400 }),
          value: 4,
        },
      ];
    },
    numberOf500PackSimCardsOptions() {
      return [
        {
          label: this.t("noPackages"),
          value: 0,
        },
        {
          label: this.t("packageOptionLabel", { packages: 1, simCards: 500 }),
          value: 1,
        },
        {
          label: this.t("packageOptionLabel", { packages: 2, simCards: 1000 }),
          value: 2,
        },
        {
          label: this.t("packageOptionLabel", { packages: 3, simCards: 1500 }),
          value: 3,
        },
        {
          label: this.t("packageOptionLabel", { packages: 4, simCards: 2000 }),
          value: 4,
        },
      ];
    },
    totalNumberOfSimCardPacks() {
      const { numberOf100Packs, numberOf500Packs } = this.form.simCards;
      return numberOf100Packs + numberOf500Packs;
    },
    isLoading() {
      return this.state.loading.status === Status.PENDING;
    },
    isLoaded() {
      return this.state.loading.status === Status.RESOLVED;
    },
    hasLoadingError() {
      return this.state.loading.status === Status.REJECTED;
    },
    isSubmitting() {
      return this.state.submitting.status === Status.PENDING;
    },
    isSubmitted() {
      return this.state.submitting.status === Status.RESOLVED;
    },
    hasSubmitError() {
      return this.state.submitting.status === Status.REJECTED;
    },
    submitButtonLabel() {
      return this.isSubmitting ? this.t("submitting") : this.t("submit");
    },
  },
  async created() {
    await translateSetup(["mybusiness"]);
    await this.setup();
  },
  methods: {
    async setup() {
      this.setState({
        state: State.LOADING,
        status: Status.PENDING,
      });

      let scopeIdResult;
      let isTeliaAdminResult;
      let hasPermissionResult;
      try {
        scopeIdResult = await getScopeIdOrThrow();
        isTeliaAdminResult = await isTeliaAdmin();
        hasPermissionResult = await hasPermission("CREATE_ORDER");
      } catch {
        this.setState({
          state: State.LOADING,
          status: Status.REJECTED,
          message: this.t("errors.couldNotGetUserInfo"),
        });
        return;
      }

      if (!hasPermissionResult) {
        this.setState({
          state: State.LOADING,
          status: Status.REJECTED,
          message: this.t("errors.noPermission"),
        });
        return;
      }

      this.scopeId = scopeIdResult;
      this.isTeliaAdmin = isTeliaAdminResult;

      if (!this.isTeliaAdmin) {
        let userInfo;

        try {
          userInfo = await getLoggedInUserInfo();
        } catch {
          this.setState({
            state: State.LOADING,
            status: Status.REJECTED,
            message: this.t("errors.couldNotGetUserInfo"),
          });
          return;
        }

        this.form.customer.email = userInfo?.mail || "";
      }

      let organisations;
      try {
        organisations = await getOrganisations();
      } catch {
        this.setState({
          state: State.LOADING,
          status: Status.REJECTED,
          message: this.t("errors.couldNotGetOrganisations"),
        });
        return;
      }

      this.organisations = organisations.map((organisation) => ({
        name: organisation.name,
        number: organisation.number,
        tscid: organisation.tscid,
      }));

      this.setState({
        state: State.LOADING,
        status: Status.RESOLVED,
      });
    },
    setState({ state = "", status = "", message = "" }) {
      switch (state) {
        case State.LOADING:
          Object.assign(this.state.loading, { status, message });
          break;
        case State.SUBMITTING:
          Object.assign(this.state.submitting, { status, message });
          break;
      }
    },
    setOrganisation(value) {
      const orgNumber = this.organisations.find((org) => org.tscid === value).number;
      this.isValidOrganisation = true;
      this.form.customer.organisation.number = orgNumber;
    },
    setReceiverPhoneNumber(input) {
      this.isValidPhoneNumber = true;
      this.form.delivery.telephoneNumber = input;
    },
    setSimCardPack(size, value) {
      this.isValidSimCardPackSelection = true;
      switch (size) {
        case SimCardPackSize.ONE_HUNDRED:
          this.form.simCards.numberOf100Packs = value;
          break;
        case SimCardPackSize.FIVE_HUNDRED:
          this.form.simCards.numberOf500Packs = value;
          break;
      }
    },
    formatRequestBody() {
      return {
        customsCreditNumber: this.form.delivery.customsCreditNumber || null,
        delivery: {
          address: {
            city: this.form.delivery.address.city,
            country: this.form.delivery.address.country || null,
            postalCode: this.form.delivery.address.postalCode,
            streetAddress: this.form.delivery.address.street,
          },
          name: this.form.delivery.fullName,
          telephoneNumber: this.form.delivery.telephoneNumber,
        },
        iccDelivery: {
          primaryEmail: this.form.delivery.icc.primaryEmail,
          secondaryEmail: this.form.delivery.icc.secondaryEmail || null,
        },
        order: {
          email: this.form.customer.email,
          organisation: {
            name: this.organisations.find(
              (organisation) => organisation.number === this.form.customer.organisation.number
            ).name,
            orgNumber: this.form.customer.organisation.number,
          },
        },
        packageQuantity: {
          numberOf100Pack: this.form.simCards.numberOf100Packs,
          numberOf500Pack: this.form.simCards.numberOf500Packs,
        },
        simCardSize: this.form.simCards.size || null,
      };
    },
    async submit() {
      this.setState({
        state: State.SUBMITTING,
        status: Status.PENDING,
      });

      if (!this.form.customer.organisation.number) {
        this.isValidOrganisation = false;
        this.setState({
          state: State.SUBMITTING,
          status: Status.IDLE,
        });
        await this.$nextTick();
        this.$refs["organisation-error-notification"].scrollIntoView();
        return;
      }

      if (!this.form.delivery.telephoneNumber) {
        this.isValidPhoneNumber = false;
      } else {
        try {
          let response = await corpValidationService.ValidationControllerService.validate(
            this.scopeId,
            {
              type: ValidationType.PHONE_NUMBER,
              value: this.form.delivery.telephoneNumber,
            }
          );
          this.isValidPhoneNumber = response?.localPart;
        } catch {
          this.isValidPhoneNumber = false;
        }
      }

      this.isValidSimCardPackSelection = this.totalNumberOfSimCardPacks > 0;

      if (!this.isValidPhoneNumber || !this.isValidSimCardPackSelection) {
        this.setState({
          state: State.SUBMITTING,
          status: Status.IDLE,
        });
        await this.$nextTick();
        this.$refs["form-error-notification"].scrollIntoView();
        return;
      }

      const requestBody = this.formatRequestBody();

      try {
        await servicesCorpBulkSimOrderV1_0_0.BulkSimOrderControllerService.orderBulkSimUsingPost(
          requestBody,
          this.scopeId
        );
      } catch (e) {
        const invalidEmailKey = "SPRINGFIELD_CUSTOMER_EMAIL_ERROR";
        const { errorKey } = JSON.parse(JSON.stringify(e?.message || ""));
        if (!errorKey || errorKey !== invalidEmailKey) {
          this.setState({
            state: State.SUBMITTING,
            status: Status.REJECTED,
            message: this.t("errors.couldNotSubmitOrder"),
          });
          return;
        }

        this.setState({
          state: State.SUBMITTING,
          status: Status.RESOLVED,
          message: this.t("orderSubmittedWithInvalidEmail", {
            email: this.form.customer.email,
          }),
        });

        this.showOrderConfirmation();
        return;
      }

      this.setState({
        state: State.SUBMITTING,
        status: Status.RESOLVED,
        message: this.t("orderConfirmationText"),
      });

      this.showOrderConfirmation();
    },
    resetForm() {
      this.form.delivery.address.street = "";
      this.form.delivery.address.postalCode = "";
      this.form.delivery.address.city = "";
      this.form.delivery.address.country = "";
      this.form.delivery.fullName = "";
      this.form.delivery.customsCreditNumber = "";
      this.form.delivery.icc.primaryEmail = "";
      this.form.delivery.icc.secondaryEmail = "";
      this.form.delivery.telephoneNumber = "";
      this.form.simCards.size = "TRIO_SIM";
      this.form.simCards.numberOf100Packs = 0;
      this.form.simCards.numberOf500Packs = 0;
      this.isValidPhoneNumber = true;
      this.isValidSimCardPackSelection = true;
    },
    showOrderConfirmation() {
      this.showModal = true;
    },
    async closeModalAndResetForm() {
      document.body.style.overflowY = "auto";
      this.setState({
        state: State.LOADING,
        status: Status.PENDING,
      });
      await this.$nextTick();
      this.resetForm();
      this.setState({
        state: State.LOADING,
        status: Status.RESOLVED,
      });
      await this.$nextTick();
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/breakpoints/variables";
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 2.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.page-header-container {
  background-color: $telia-gray-50;
  padding: $telia-spacing-16 0;
  margin-bottom: $telia-spacing-48;
}

.notification-container {
  margin-bottom: $telia-spacing-32;
}

.page-title-wrapper {
  margin: $telia-spacing-32 0 0 0;
}

.page-info-wrapper {
  margin: $telia-spacing-4 0 $telia-spacing-12 0;
}

.organisation-select-skeleton {
  height: 4.2rem;
  width: 42rem;
}

.form-container {
  padding-bottom: $telia-spacing-48;
}

.section-title {
  margin-bottom: $telia-spacing-12;
}

.section-title-skeleton {
  height: 3rem;
  width: 24rem;
  margin-bottom: $telia-spacing-12;
}

.section-paragraph {
  margin: $telia-spacing-12 0 $telia-spacing-16 0;
}

.section-paragraph-skeleton {
  height: 2rem;
  width: 45rem;
  margin: $telia-spacing-12 0 $telia-spacing-16 0;
}

.input-group-container {
  margin-bottom: $telia-spacing-32;
}

.input-wrapper {
  max-width: 42rem;
  margin-bottom: $telia-spacing-20;

  &__custom-error {
    telia-text-input {
      margin-bottom: 0.8rem;
    }
  }
}

.input-skeleton-wrapper {
  max-width: 42rem;
  margin-bottom: $telia-spacing-12;
}

.chip-choice-container {
  margin-bottom: $telia-spacing-16;
}

.chip-choice-skeleton {
  height: 4rem;
  width: 32.7rem;
}

.button-skeleton {
  height: 4rem;
  width: 9.6rem;
}

.form-error-container {
  margin-bottom: $telia-spacing-16;
}

.modal-title {
  margin-bottom: $telia-spacing-16;
}

.modal-button-footer {
  padding: $telia-spacing-4 $telia-spacing-16 $telia-spacing-16 $telia-spacing-16;

  @media (min-width: $telia-breakpoint-medium) {
    padding: $telia-spacing-4 $telia-spacing-32 $telia-spacing-32 $telia-spacing-32;
  }
}
</style>
